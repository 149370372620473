import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { useAddItemsToCart } from '@lib/context';

import CheckMark from '@svg/checkmark.svg';

import { ModuleWrapper } from '../moduleWrapper';
import { SanityImage } from '@components/sanity/sanityImage';
import { ProductPrice } from '@components/product/productPrice';
import { DragCarousel } from '@components/carousels/dragCarousel';

const TeaserCard = ({ selectedProducts, setSelectedProducts, ...props }) => {
  const { bottleShot, title, price, bgColor, _id } = props;
  const isActive = selectedProducts.some((item) => item._id === _id);

  const handleClick = () => {
    setSelectedProducts((prevState) => {
      const newItems = [...prevState];
      const productIndex = prevState.findIndex((item) => item._id === _id);
      if (productIndex > -1) {
        newItems.splice(productIndex, 1);
      } else {
        newItems.push({ ...props });
      }
      return newItems;
    });
  };

  return (
    <div className="w-2/3 flex-1 rounded-xl px-4 pb-6 group relative md:w-auto">
      <button
        className={cx(
          'w-6 h-6 rounded-full border-2 border-black absolute top-4 right-4 flex items-center justify-center z-20 md:w-8 md:h-8',
          { 'bg-black': isActive }
        )}
        onClick={handleClick}
      >
        <CheckMark
          className={cx('text-white fill-current w-4', {
            'opacity-0': !isActive,
          })}
        />
      </button>
      {/* Span */}
      <span
        style={{ backgroundColor: bgColor && bgColor.hex }}
        className="opacity-50 group-hover:opacity-100 transition-all absolute w-full h-full z-0 top-0 left-0 rounded-xl"
      />
      <div className="w-32 mx-auto relative z-10 md:w-64">
        <div className="aspect-w-1 aspect-h-1 relative">
          <SanityImage
            image={bottleShot}
            className="absolute inset-0 object-contain object-center"
          />
        </div>
      </div>
      <div className="text-center relative z-10">
        <h4 className="blockH5 font-medium">{title}</h4>
        <h5 className="text-sm md:text-lg font-medium">
          <ProductPrice price={price} />
        </h5>
      </div>
    </div>
  );
};

const RelatedPurchase = ({ title, products, config }) => {
  const [selectedProducts, setSelectedProducts] = useState(products);
  const [bundlePrice, setBundlePrice] = useState(0);

  const breakpoints = useBreakpoint();

  const addTocart = useAddItemsToCart();

  const addItems = () => {
    const items = selectedProducts.map((item) => {
      return {
        id: item.variants[0].id,
        qty: 1,
      };
    });

    addTocart(items);
  };

  useEffect(() => {
    let total = 0;
    selectedProducts.map((item) => (total += item.price));
    setBundlePrice(total);
  }, [selectedProducts]);

  return (
    <ModuleWrapper {...config}>
      <div className="px-gutter overflow-hidden xl:flex xl:gap-x-10">
        <div className="self-center lg:flex lg:justify-between xl:flex-col">
          <h2 className="blockH2 font-medium text-center mb-10 lg:text-3xl lg:text-left">
            {title}
          </h2>

          {selectedProducts.length > 0 && breakpoints.xl && (
            <div>
              <button className="btn mt-6" onClick={addItems}>
                Add {selectedProducts.length} to Cart <span className="px-2">-</span>
                <ProductPrice price={bundlePrice} />
              </button>
            </div>
          )}
        </div>
        {breakpoints.xl ? (
          <div className="flex lg:gap-x-4 xl:gap-x-6">
            {products.map((item) => (
              <TeaserCard
                {...item}
                setSelectedProducts={setSelectedProducts}
                selectedProducts={selectedProducts}
              />
            ))}
          </div>
        ) : (
          <>
            <DragCarousel>
              <div className="flex gap-x-4">
                {products.map((item) => (
                  <TeaserCard
                    {...item}
                    setSelectedProducts={setSelectedProducts}
                    selectedProducts={selectedProducts}
                  />
                ))}
              </div>
            </DragCarousel>
          </>
        )}
        {selectedProducts.length > 0 && (
          <div className="flex justify-center w-full lg:hidden">
            <button className="btn mt-6" onClick={addItems}>
              Add {selectedProducts.length} to Cart - <ProductPrice price={bundlePrice} />
            </button>
          </div>
        )}
      </div>
    </ModuleWrapper>
  );
};

export default RelatedPurchase;
